$tablet-min-width: 576px;
$desktop-min-width: 1025px;

@mixin mobile {
  @media (max-width: calc($tablet-min-width - 1px)) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tablet-min-width) and (max-width: calc($desktop-min-width - 1px)) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop-min-width) {
    @content;
  }
}
