@import './styles/utils';
@import './styles/responsive';

$font-title: 'Montserrat', sans-serif;
$font-paragraph: 'Hind', sans-serif;

.App {
  width: 100%;
  height: 100%;
  display: flex;

}

.App-wrap {
  position: relative;
  padding: rem(24);
  height: min-content;
  margin: auto;

  @include desktop {
    padding: rem(40) rem(144) rem(80);

  }
}

.App-content {
  display: flex;
  flex-direction: column-reverse;

  @include desktop {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: rem(96);
  }
}

.App-col {
  position: relative;
  max-width: rem(590);

  &:first-child {
    flex: 1.22;
  }

  &:nth-child(2) {
    flex: 1;
  }
}

.App-title {
  font-family: $font-title;
  font-weight: 700;
  font-size: rem(28);
  line-height: rem(32);
  letter-spacing: rem(-0.64);

  @include desktop {
    font-size: rem(48);
    line-height: rem(60);
  }
}

.App-paragraph {
  font-size: rem(14);
  font-family: $font-paragraph;
  line-height: 140%;
  margin: rem(24) 0;

  @include desktop {
    font-size: rem(20);
  }
}

.App-contact {
  position: relative;
  width: fit-content;
  border: rem(3) solid #000000;
  border-radius: rem(102);
  background: #FFFFFF;

  &:hover {
    background: #BDF9E3;

    .App-contact_bg {
      display: initial;
    }

    .App-contact_link {
      padding-left: rem(24);
      padding-right: rem(28);
    }
  }
}

.App-contact_bg {
  position: absolute;
  left: rem(-6);
  top: rem(8);
  background: #BD5252;
  border: rem(2) solid #000000;
  border-radius: rem(102);
  width: 100%;
  height: 100%;
  z-index: -1;
  display: none;
}

.App-contact_link {
  display: flex;
  font-size: rem(26);
  line-height: rem(20);
  font-family: $font-paragraph;
  font-weight: 700;
  font-style: normal;
  text-decoration: none;
  letter-spacing: rem(-0.32);
  color: #162950;
  padding: rem(22) rem(26);
}

.Animation-logo {
  max-width: rem(170);
  margin-bottom: rem(12);

  @include desktop {
    max-width: rem(304);
  }
}

.Animation-pepe {
  position: relative;
  max-width: rem(248);
  display: flex;
  margin: rem(90) auto;

  img {
    width: 100%;
    height: auto;
  }

  @include desktop {
    max-width: rem(464);
    margin: 0;
  }
}

.Animation-img {
  &_rocket {
    &_dk {
      position: absolute;
      width: rem(68.63);
      height: rem(68.63);
      transform: rotate(30deg);
      top: 55%;
      left: rem(-84);
      display: none;

      @include desktop {
        display: flex;
      }
    }

    &_mb {
      position: absolute;
      width: rem(57);
      height: rem(57);
      transform: rotate(30deg);
      left: rem(-30);
      bottom: rem(-55);

      @include desktop {
        display: none;
      }
    }
  }

  &_dollar {
    &_dk {
      position: absolute;
      width: rem(81.83);
      height: rem(81.83);
      transform: rotate(-15.39deg);
      right: 12.34%;
      display: none;

      @include desktop {
        display: flex;
      }
    }

    &_mb {
      position: absolute;
      width: rem(48);
      height: rem(48);
      transform: rotate(-15.39deg);
      right: rem(-30);

      @include desktop {
        display: none;
      }
    }
  }

  &_up {
    position: absolute;
    width: rem(60);
    height: rem(60);
    transform: rotate(6.18deg);
    top: rem(-60);
    left: rem(0);

    @include desktop {
      width: rem(86);
      height: rem(86);
      top: rem(-86);
      left: rem(-30);
    }
  }

  &_coinstack {
    position: absolute;
    width: rem(54);
    height: rem(54);
    transform: rotate(-15.39deg);
    right: rem(-14);
    bottom: rem(-28);

    @include desktop {
      width: rem(79);
      height: rem(79);
      right: rem(-65);
      bottom: rem(-14);
    }
  }

  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
}

@media (prefers-reduced-motion: no-preference) {
  .Animation-img {
    animation: Img-Animation 5s ease infinite;
  }
}

@keyframes Img-Animation {
  40% {
    width: 100%;
    height: 100%;
  }

  70% {
    width: 110%;
    height: 110%;
  }

  100% {
    width: 100%;
    height: 100%;
  }
}
